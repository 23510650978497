import axios from 'axios'
import { store } from '../store'
import auth from "../auth/authService";

const AXIOS_CMS = axios.create({
    baseURL: process.env.VUE_APP_CMS_BACKEND_URL,
    timeout: 120000
});

AXIOS_CMS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        'impersonate': `${auth.getImpersonation()}`
    };
    return config;
});

AXIOS_CMS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

export const uploadMediaAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_CMS.post(`/api/media/upload?blockId=${data.get('blockId')}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const publishCmsChangesAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_CMS.post(`/api/draft/publish`, data)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const discardCmsChangesAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_CMS.post(`/api/draft/discard`, data)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const saveCmsDraftAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_CMS.post(`/api/draft/save`, data)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getPageCmsAPI = (data) =>
    new Promise((resolve, reject) => {
        let url = `/api/page/data?language=${data.language}&pagesHri=${data.pagesHri}`;
        if (data.oemId) {
            url = url + `&oemId=${data.oemId}`;
        }
        if (data.blocksHri) {
            url = url + `&blocksHri=${data.blocksHri}`;
        }
        AXIOS_CMS.get(url)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });