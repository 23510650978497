<template>
  <div v-show="isLoading" class="loaderImage">
    <img src="../assets/images/loader.gif" />
  </div>
</template>

<script>
export default {
  props: ["isLoading"],
  name: "loader"
};
</script>

<style>
.loaderImage {
  z-index: 9999 !important;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, 0.3);
}

.loaderImage img {
    height: 80px;
    width: 80px;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
