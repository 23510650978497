export default {
    isValidEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    isValidMultipleEmail(email) {
        let re = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;
        return re.test(email);
    },
    isValidPhone(phone) {
        let re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{7})?([0-9]{3})[-. ]?([0-9]{4})$/;
        return re.test(phone);
    },
    isValidZip(zip) {
        let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
        return zipCodePattern.test(zip);
    },
    isValidCaZip(zip) {
        let zipCodePattern = /^[A-Z]\d[A-Z][\s ]?\d[A-Z]\d$/;
        return zipCodePattern.test(zip);
    },
    isValidZipCode(regx, zipCode) {
        return regx.test(zipCode);
    },
    isValidCCNumber(ccNumber) {
        // accept only digits and dashes
        if (/[^0-9-]+/.test(ccNumber)) {
            return false;
        }
        var nCheck = 0,
            bEven = false;

        ccNumber = ccNumber.replace(/\D/g, "");

        for (var n = ccNumber.length - 1; n >= 0; n--) {
            var cDigit = ccNumber.charAt(n);
            var nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) {
                    nDigit -= 9;
                }
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    },
    isValidSecurityCode(ccSecurityCode) {
        return ccSecurityCode.length >= 3;
    },
    zipCodeRegxByCountryMap() {
        const regxZipCode = {
            ca: /^[A-Z]\d[A-Z][\s ]?\d[A-Z]\d$/,
            canada: /^[A-Z]\d[A-Z][\s ]?\d[A-Z]\d$/,
            us: /^\d{5}$|^\d{5}-\d{4}$/,
            usa: /^\d{5}$|^\d{5}-\d{4}$/
        };
        const mapOfZipCodeRegxs = new Map(Object.entries(regxZipCode));
        return mapOfZipCodeRegxs;
    },
    regExpByCountry(country) {
        country = country ? country.toLowerCase() : 'us';
        return this.zipCodeRegxByCountryMap().get(country);
    }
}