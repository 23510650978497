import Vue from 'vue';
import Vuex from 'vuex';
import {dwc} from '../modules';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        dwc,
    },
    mutations: {
        updateIsLoading (state, Loading) {
            state.isLoading = Loading;
        }
    },
});