import axios from 'axios'
import {store} from '../store'
import auth from "../auth/authService";

const AXIOS = axios.create({
    baseURL: '/',
    timeout: 120000
});
const AXIOS_Export = axios.create({
    baseURL: '/',
    timeout: 120000
});
// before a request is made start the nprogress
AXIOS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
    };
    return config;
});
// before a response is returned stop nprogress
AXIOS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

AXIOS_Export.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        // 'content-type': 'application/x-www-form-urlencoded'
    };
    return config;
});
AXIOS_Export.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});


export const initReportInterceptors = () => {
    auth.addInterceptors(AXIOS);
    auth.addInterceptors(AXIOS_Export);
};

export const generateReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/reports/generate/${data.ReportName}`,
            data.RequestPayLoad,
            {
                headers: { 'content-type': 'application/json' }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getReportsFilterAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/getDataForSelect${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getReportFormAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/getReportForm/${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const redirectToCalldripSsoAPI = (dealerId) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/sso/calldrip/auth/${dealerId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getRedirectToCalldripUrlAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/sso/calldrip/redirectUrl`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const exportReportsDataAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.post(`/api/portal/reports/generate/${data.reportName}/${data.exportFormat}?reportLeadType=${data.reportLeadType}`,
            data.RequestPayLoad,
            {
                headers: { 'content-type': 'application/json' },
                responseType: 'blob'
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getConciergeReportDataPageAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/ConciergeAggregatedSummaryReport/dataPage`,
            {
                params: { ...data },
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getAffinityDealerSalesReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/AffinityDealerSalesReport/dataPage?${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getShopperContactReportDataPageAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/ShopperContactAggregatedSummaryReport/dataPage`,
            {
                params: {...data},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getThresholdsAPI = (oemId) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/filter/getThresholds/${oemId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getPeriodForReportAPI = (oemId) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/reports/filter/getPeriodForReport/${oemId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const generateReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/reports/generate/${data.ReportName}?leadThreshold=${data.queryParams.leadThreshold}&closeRateThreshold=${data.queryParams.closeRateThreshold}`,
            data.RequestPayLoad,
            {
                headers: { 'content-type': 'application/json' }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const generateLeadDetailsReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/reports/lead-details?page=`+data.page+`&size=`+data.size,
            data,
            {
                headers: { 'content-type': 'application/json' }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const exportReportsRecentFullyConvertedDealerRankingAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.post(`/api/portal/reports/generate/${data.reportName}/${data.exportFormat}?reportLeadType=${data.reportLeadType}&leadThreshold=${data.leadThreshold}&closeRateThreshold=${data.closeRateThreshold}`,
            data.RequestPayLoad,
            {
                headers: { 'content-type': 'application/json' },
                responseType: 'blob'
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });