<template>
    <div class="adminup_serach_sec" :class="[impersonated ? 'impersonated'  : '']">
        <div class="container">
            <div class="admin_searchbar">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-3">
                        <div class="left_sec">
                            <label class="control-label">{{ $t("app.OEM")}}</label>
                            <select :disabled="impersonated"
                                    v-model="selectedOEM" class="form-control table_drpdwn drop_arrow"
                                    name="Line Make">
                                <option>All</option>
                                <option v-bind:key="eachOem" v-for="eachOem in allOEMs">{{eachOem}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-9">
                        <div class="right_sec searchbox">
                            <label class="control-label" >{{impersonated ? $t("app.LoggedInUser") : $t("app.ImpersonateUser")}}</label>
                            <div class="input-group impersonateUserInputDiv">
                                <input :disabled="impersonated"
                                       type="text"
                                       v-model="search"
                                       class="form-control searchbar"
                                       style="z-index: 1;"
                                       :placeholder="$t('app.SearchUser')"
                                       @input="onChange"
                                       @keyup.down="onArrowDown" @keyup.up="onArrowUp"
                                       @keyup.enter="onEnter"
                                >
                                <ul id="autocomplete-results" style="margin-top: 35px; width: 94%" v-show="isOpen"
                                    class="autocomplete-results">
                                    <li class="loading" v-if="isLoading">
                                        Loading results...
                                    </li>
                                    <li v-else v-for="dealer in results" :key="dealer.loginName"
                                        @click="setResult(dealer)" class="autocomplete-result"
                                        :class="[dealer.loginName === arrowCounter ? 'is-active' : '']"
                                    >
                                        {{ dealer.userName }} , {{ dealer.loginName }}
                                    </li>
                                </ul>


                                <div class="input-group-addon addon">
                                    <img src="../../assets/images/svg/search.svg" alt="Search" title="Search" class="img-responsive">
                                </div>
                            </div>

                            <button @click="impersonateToggle" class="viewlead_btn" type="button">
                                {{impersonated ? $t("app.Reset") : $t("app.Apply")}}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {sortByKey} from '@/config/Config';
import {mapState} from 'vuex';
import filterSettingsStorageMixin from '@/components/mixin/FilterStorage.vue'

export default {
        name: "ImpersonateUserToolbar",
        data() {
            return {
                selectedOEM: 'All',
                impersonateSearchBox: '',
                impersonateSearchBoxDisable: false,
                search: null,
                isOpen: false,
                isLoading: false,
                results: [],
                arrowCounter: 0,
            }
        },
        mixins: [filterSettingsStorageMixin],
        computed: {
            ...mapState('dwc', ['UserDetails', 'usersToImpersonate', 'oems']),
            allOEMs: function () {
                return this.oems.map(oem => oem.name);
            },
            impersonated: function () {
                return this.UserDetails && this.UserDetails.impersonator;
            },
            impersonatedUserLogin: function() {
                const me = this;
                if (me.UserDetails && me.UserDetails.impersonator) {
                    return me.UserDetails.impersonator.login;
                }
                return undefined;
            },
        },
        created() {
            const me = this;
            me.search = me.impersonatedUserLogin;
            me.$store.dispatch('dwc/getOEMs');
        },
        methods: {
            impersonateToggle() {
                const me = this;
                if (me.impersonated) {
                    window.localStorage.removeItem('oemSkinName');
                    me.$store.dispatch('dwc/deImpersonateUserByLogin').then(() => {
                        me.clearAllSavings();
                        window.location.href = "/";
                    });
                } else if (me.search) {
                    const data = {};
                    data.loginName = me.search.trim();
                    if (me.selectedOEM !== 'All') {
                        data.oemName = me.selectedOEM;
                    }
                    me.$store.dispatch('dwc/impersonateUserByLogin', data).then(() => {
                        me.clearAllSavings();
                        window.location.href = "/";
                    });
                }
            },
            onChange() {
                const me = this;
                if (me.search && me.search.length >= 2) {
                    me.filterResults(me.search.trim());
                    me.isOpen = true;
                } else if (me.search.length === 0) {
                    me.isOpen = false;
                    me.search = '';
                }
            },
            filterResults(searchData) {
                // first uncapitalize all the things
                const me = this;
                const queryString = `?term=${searchData}`;
                me.$store.dispatch('dwc/getUsersToImpersonate', queryString)
                    .then((res) => {
                        let sortedSearch = [];
                        if (res.status === 200 && res.data) {
                            sortedSearch = res.data;

                            if (me.selectedOEM && me.selectedOEM !== 'All') {
                                sortedSearch = sortedSearch.filter(
                                    item => item.oemName === me.selectedOEM
                                );
                            }

                            var searchString = me.search.trim().toLowerCase();

                            sortedSearch = sortedSearch.filter(item => {
                                return item && ((item.loginName.toLowerCase().indexOf(searchString) > -1) ||
                                    (item.userName.toLowerCase().indexOf(searchString) > -1));
                            });

                            sortedSearch = sortByKey(sortedSearch, 'userName');
                        }
                        const notFound = 'No Users Found';
                        if (sortedSearch === '') {
                            window.$('#autocomplete-results').html('<li>' + notFound + '</li>')
                        } else {
                            window.$('#autocomplete-results').find('li:contains(' + notFound + ')').remove();
                        }
                        me.results = sortedSearch;
                    });
            },
            setResult(dealer) {
                const me = this;
                if (dealer) {
                    me.search = `${dealer.loginName}`;
                    me.isOpen = false;
                    me.selectedDealerCode = dealer.loginName;
                }
            },
            handleClickOutside(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.isOpen = false;
                }
                this.arrowCounter = -1;
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.setResult(this.results[this.arrowCounter]);
                this.arrowCounter = -1;
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }
</script>

<style scoped>
    @media (-ms-high-contrast: none) {
        .impersonateUserInputDiv {
            width: 526px !important;
        }
    }
</style>