<template>
  <div class="home">
    <HomeComponents msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src folder
import HomeComponents from '@/components/HomeComponents.vue'

export default {
  name: 'home',
  components: {
    HomeComponents
  }
}
</script>
