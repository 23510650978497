import '@/gtm'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import AuthPlugin from "./plugins/auth"
import RoutingPlugin from "./plugins/routePath"
import {initCommonInterceptors} from "./services/CommanServices"
import {initDealerInterceptors} from "./services/DealerService"
import {initReportInterceptors} from "./services/ReportsServices"
import {initEnrollmentInterceptors} from "./services/SetupEnrollmentServices"
import VueCarousel from '@chenfengyuan/vue-carousel';
import {store} from './store'
import FormField from "./components/FormField";
import {initComplianceInterceptors} from "./services/ComplianceServices";
import VueCompositionAPI from '@vue/composition-api'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from "./i18n";
import VueGtag from 'vue-gtag';


import './dompurify';

Vue.config.productionTip = false;

const GA_MEASUREMENT_ID = 'G-71MYHWVKB5';

window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', GA_MEASUREMENT_ID);

Vue.use(AuthPlugin);
Vue.use(RoutingPlugin);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueCarousel);
Vue.use(VueCompositionAPI)
Vue.component(FormField.name, FormField);
Vue.use(VueGtag, {
  config: {
    id: GA_MEASUREMENT_ID,
    params: {
      send_page_view: false
    }
  }
}, router);


export const bus = new Vue();


  new Vue({
    beforeCreate: function() {
      initCommonInterceptors();
      initDealerInterceptors();
      initReportInterceptors();
      initComplianceInterceptors();
      initEnrollmentInterceptors();
    },
    store,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app');


