import axios from 'axios'
import qs from 'qs';
import {store} from '../store'

const AXIOS = axios.create({
    baseURL: '/oauth',
    timeout: 120000
});
const AXIOS_1 = axios.create({
    baseURL: '/oauth',
    timeout: 120000
});
const AXIOS_2 = axios.create({
    baseURL: '/sso',
    timeout: 120000
});
// before a request is made start the nprogress
AXIOS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    return config;
});
// before a response is returned stop nprogress
AXIOS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});
// before a request is made start the nprogress
AXIOS_2.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    return config;
});
// before a response is returned stop nprogress
AXIOS_2.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

export const postLoginInfo = (user, password, rememberMe) =>
    new Promise((resolve, reject) => {
        AXIOS.post('/token', qs.stringify({
            username: user,
            password: password,
            grant_type: 'password',
            'remember-me': rememberMe
        }), {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                auth: {
                    username: process.env.VUE_APP_CLIENT_ID,
                    password: process.env.VUE_APP_CLIENT_SECRET
                }
            })
            .then(response => {
                resolve(response)
            })
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });
export const reGenerateTokenAPI = (refresh_token) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post('/token', qs.stringify({
            refresh_token,
            grant_type: 'refresh_token'
        }), {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                auth: {
                    username: process.env.VUE_APP_CLIENT_ID,
                    password: process.env.VUE_APP_CLIENT_SECRET
                }
            })
            .then(response => resolve(response))
            .catch((error) => {
                // store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });
export const forgotPasswordAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_2.post('/auth/forgotPassword', qs.stringify(data))
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });
export const implicitAuthorize = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.post('/authorize', qs.stringify({
            client_id: process.env.VUE_APP_CLIENT_ID,
            response_type: 'token',
            implicit: true,
            redirect_uri: window.location.origin
        }))
            .then(() => {
                resolve();
            })
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });