<template>
    <div :class="[isOpen ? 'open'  : 'close']" class="modal fade change_password opacity-1" role="dialog">
        <div class="covering_div"></div>
        <div class="modal-dialog no_transform">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <!-- <button type="button" @click="hideModel" class="close" data-dismiss="modal">&times;</button> -->
                    <button type="button" @click="hideModel" class="close" data-dismiss="modal" style="display: block;">×</button>
                    <div style="pointer-events: none" class="info_heading">
                        <h3>{{ $t("app.CHANGE_PASSWORD") }}</h3>
                    </div>
                </div>
                <div class="modal-body">
                    <div v-if="ChangePasswordPermission && ChangePasswordPermission.canEdit" class="changepwd_form">
                        <div v-if="isSuccess" class="green"> 
                            {{ successMessage }}
                        </div>
                        <div v-else class="red"> 
                            {{ APIResp }}
                        </div>
                        <form>
                            <div class="form-group" :class="[showError(1, errors) ? 'form_error' : '']">
                                <label class="control-label">{{ $t("app.CURRENT_PASSWORD") }}: <span class="asterisk">*</span></label>
                                <input class="form-control" type="password" v-model="Current_Password" :placeholder='$t("app.FOR_SECURITY_REASON")'>
                                <label class="required_validation" :class="[!showError(1, errors) ? 'hide_error': '']">
                                    {{ showErrorText(1, errors) }}
                                </label>
                            </div>
                            <div class="form-group" :class="[(showError(2, errors) && errorsNew.length === 0) ? 'form_error' : '']" style="margin-top: 25px;">
                                <label class="control-label">{{ $t("app.NEW_PASSWORD") }}: <span class="asterisk">*</span></label>
                                <input v-model="New_Password" @keyup="checkPasswordStringth($event)" class="form-control" type="password" :placeholder='$t("app.CHOOSE_STRONG_PASSWORD")'>
                                <label class="required_validation" :class="[(!showError(2, errors) && errorsNew.length === 0) ? 'hide_error': '']">
                                    {{ showErrorText(2, errors) }}
                                </label>
                                <label class="required_validation" :class="[(!showError(10, errorsNew) && errors.length === 0) ? 'hide_error': '']">
                                    {{ showErrorText(10, errorsNew) }}
                                </label>
                                <p style="margin-top: 25px;">{{ $t("app.PASSWORD_MUST_CONTAIN") }}</p>
                                <ul>
                                    <li v-if="isLengthMatch === 0">{{ $t("app.AT_LEAST_CHARS") }}</li>
                                    <li v-else-if="isLengthMatch === 1" class="green"><i class="fas fa-check"></i> {{ $t("app.AT_LEAST_CHARS") }}</li>
                                    <li v-else class="red"><i class="fas fa-times"></i> {{ $t("app.AT_LEAST_CHARS") }}</li>

                                    <li v-if="isUppserCaseMatch === 0">{{ $t("app.AT_LEAST_UPP_LOW") }}</li>
                                    <li v-else-if="isUppserCaseMatch === 1" class="green"><i class="fas fa-check"></i>{{ $t("app.AT_LEAST_UPP_LOW") }}</li>
                                    <li v-else class="red"><i class="fas fa-times"></i>{{ $t("app.AT_LEAST_UPP_LOW") }}</li>

                                    <li v-if="isNumericMatch === 0">{{ $t("app.AT_LEAST_NUM") }}</li>
                                    <li v-else-if="isNumericMatch === 1" class="green"><i class="fas fa-check"></i>{{ $t("app.AT_LEAST_NUM") }}</li>
                                    <li v-else class="red"><i class="fas fa-times"></i> {{ $t("app.AT_LEAST_NUM") }}</li>

                                    <li v-if="isSpacialCharMatch === 0">{{ $t("app.AT_LEAST_SPECIAL") }}</li>
                                    <li v-else-if="isSpacialCharMatch === 1" class="green"><i class="fas fa-check"></i> {{ $t("app.AT_LEAST_SPECIAL") }}</li>
                                    <li v-else class="red"><i class="fas fa-times"></i> {{ $t("app.AT_LEAST_SPECIAL") }}</li>
                                </ul>
                            </div>
                            <div class="form-group" :class="[showError(3, errors) ? 'form_error' : '']">
                                <label class="control-label">{{ $t("app.CONFIRM_PASSWORD") }}: <span class="asterisk">*</span></label>
                                <input v-model="Confirm_Password" class="form-control" type="password" :placeholder='$t("app.CONFIRM_PASSWORD")'>
                                <label class="required_validation" :class="[!showError(3, errors) ? 'hide_error': '']">
                                    {{ showErrorText(3, errors) }}
                                </label>
                            </div>
                            <label class="asterisk_indi" style="margin-top: 25px;"><span class="asterisk">*</span> - {{ $t("app.INDICATE_MANDATORY") }}</label>
                            <button @click="onClickChangePassword" class="contact_submit" type="button">{{ $t("app.UPDATE_PASSWORD") }}</button>
                        </form>
                    </div>
                    <div v-else>
                        <div class="info_heading">
                            <h3>{{ noPermission }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { customMessages } from '../config/VaidationMessages';
import { mapState } from 'vuex';
export default {
    props: ['isOpen'],
    name: 'change_password',
    data() {
        return {
            Current_Password: null,
            New_Password: null,
            Confirm_Password: null,
            noPermission: customMessages.ChangePassword.IsPermissionMessage,
            successMessage: customMessages.ChangePassword.SuccessMessage,
            isLengthMatch: 0,
            isUppserCaseMatch: 0,
            isNumericMatch: 0,
            isSpacialCharMatch: 0,
            errors: [],
            errorsNew: [],
            canLocalEdit: true,
            strengthPasswordDescription: null,
            APIResp: null,
            isSuccess: null
        }
    },
    computed: {
        ...mapState('dwc', ['ChangePasswordPermission', 'ChangePasswordResp', 'ChangePasswordRespError'])
    },
    // created() {
        // const me = this;
        // me.$store.dispatch('dwc/checkChangePasswordPermission')
        // .then(()=> {
        //     if(me.ChangePasswordPermission) {
        //         const canEdit = me.ChangePasswordPermission && me.ChangePasswordPermission.canEdit;
        //         me.canLocalEdit = canEdit;
        //         me.strengthPasswordDescription = me.ChangePasswordPermission && me.ChangePasswordPermission.strengthPasswordDescription;
        //     }
        // })
    // },
    methods: {
        hideModel() {
            const me = this;
            me.successMessage = null;
            me.APIResp = null;
            me.errors = [];
            me.errorsNew = [];
            me.isLengthMatch = 0;
            me.isUppserCaseMatch =0;
            me.isNumericMatch = 0;
            me.isSpacialCharMatch = 0;
            me.Current_Password = null;
            me.New_Password = null;
            me.Confirm_Password = null;
            this.$emit('changePasswordModelShowHide');
        },
        showError(id, errors) {
            let isActive = false;
            if (errors.length > 0) {
                let errorData = (errors.filter(err => err.id === id));
                isActive = errorData.length > 0 && errorData[0].isActive
            }
            return isActive;
        },
        showErrorText(id, errors) {
            let error = '';
            if (errors.length > 0) {
                let errorData = (errors.filter(err => err.id === id));
                error = errorData.length > 0 && errorData[0].error
            }
            return (!error) ? '' : error;
        },
        formValidation() {
            this.errorsNew = [];
            this.errors = [];
            if (!this.Current_Password) {
                this.errors.push({
                    isActive: true,
                    id: 1,
                    error: customMessages.ChangePassword.CuurrentPassword
                });
            }
            if (!this.New_Password) {
                this.errors.push({
                    isActive: true,
                    id: 2,
                    error: customMessages.ChangePassword.NewPassword
                });
            }
            if (!this.Confirm_Password) {
                this.errors.push({
                    isActive: true,
                    id: 3,
                    error: customMessages.ChangePassword.ConfirmPassword
                });
            } else if (this.New_Password !== this.Confirm_Password) {
                this.errors.push({
                    isActive: true,
                    id: 3,
                    error: customMessages.ChangePassword.PasswordMatch
                });
            }
            if (!this.errors.length) {
                return true;
            }
        },
        checkPasswordStringth(evt) {
            const me = this;
            const value = evt.target.value;
            const hasNumber = value => new RegExp(/[0-9]/).test(value);
            const hasMixed = value => new RegExp(/[A-Z]/).test(value) && new RegExp(/[a-z]/).test(value);
            const hasSpecial = value => new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
            if(value.length <= 0) {
                me.isLengthMatch = 0;
                me.isUppserCaseMatch =0;
                me.isNumericMatch = 0;
                me.isSpacialCharMatch = 0;
            } else {
                if (value.length >= 8) {
                    me.isLengthMatch = 1;
                } else {
                    me.isLengthMatch = 2;
                }
                if (hasMixed(value)) {
                    me.isUppserCaseMatch = 1;
                } else {
                    me.isUppserCaseMatch = 2;
                }
                if (hasNumber(value)) {
                    me.isNumericMatch = 1;
                } else {
                    me.isNumericMatch = 2;
                }
                if (hasSpecial(value)) {
                    me.isSpacialCharMatch = 1;
                } else {
                    me.isSpacialCharMatch = 2;
                }
            }
        },
        passwordStringthCheck() {
            const me = this;
            me.errorsNew = [];
            me.errors = [];
            if(me.isLengthMatch === 2) {
                me.errorsNew.push({
                    isActive: true,
                    id: 10,
                    error: customMessages.ChangePassword.PasswordStringth
                });
            }
            if(me.isUppserCaseMatch === 2) {
                me.errorsNew.push({
                    isActive: true,
                    id: 10,
                    error: customMessages.ChangePassword.PasswordStringth
                });
            }
            if(me.isNumericMatch === 2) {
                me.errorsNew.push({
                    isActive: true,
                    id: 10,
                    error: customMessages.ChangePassword.PasswordStringth
                });
            }
            if(me.isSpacialCharMatch === 2) {
                me.errorsNew.push({
                    isActive: true,
                    id: 10,
                    error: customMessages.ChangePassword.PasswordStringth
                });
            }
            if (!me.errorsNew.length) {
                return true;
            }

        },
        onClickChangePassword() {
            let me = this;
            if(me.passwordStringthCheck()) {
                if(me.formValidation()) {
                    let CurrPass = me.Current_Password;
                    let NewPass = me.New_Password;
                    let ConPass = me.Confirm_Password;
                    let data = {
                        confirmPassword: ConPass,
                        newPassword: NewPass,
                        password: CurrPass
                    };
                    this.$store.dispatch('dwc/changePassword', data)
                        .then(() => {
                            me.successMessage = customMessages.ChangePassword.SuccessMessage;
                            if(me.ChangePasswordResp) {
                                me.isSuccess = true;
                            } else if(me.ChangePasswordRespError) {
                                me.APIResp = me.ChangePasswordRespError.errors.length>0 && me.ChangePasswordRespError.errors[0];
                                me.isSuccess = false;
                            }
                            me.Current_Password = null;
                            me.New_Password = null;
                            me.Confirm_Password = null;
                            me.isLengthMatch = 0;
                            me.isUppserCaseMatch =0;
                            me.isNumericMatch = 0;
                            me.isSpacialCharMatch = 0;
                        })
                }
            }
        }
    }
}
</script>
<style>
.red {
    color: red !important;
}
.green {
    color: forestgreen !important;
}
.open {
    display: block;
}

.close {
    display: none;
}
.changepwd_form .required_val {
    color: red !important;
}
.changepwd_form .required_validation {
    color: red !important;
    font-weight: 600;
    font-size: 14px;
    margin: 3px 0;
    position: absolute;
    padding-left: 0px;
}
.changepwd_form .form_error .form-control {
    border: 1px solid red !important;
    box-shadow: none;
}
.hide_error {
    display: none;
}
.show_success{
    color: green !important;
}
</style>
