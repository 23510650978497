<template>
    <div
            :class="[open ? 'open'  : 'close']"
            class="modal fade change_password opacity-1"
            role="dialog"
    >
        <div class="covering_div"></div>
        <div class="modal-dialog no_transform modal-alert-msg">
            <div class="modal-content">
                <div class="modal-header error-header">
                    <button type="button" @click="closeWindow" class="close" data-dismiss="modal"
                            style="display: block;">×
                    </button>
                    <div class="info_heading">
                        <h3>Unable to Complete Request</h3>
                    </div>
                </div>

                <div class="modal-body">
                  <P class="modal-text-body"> We’re sorry, something went wrong. Our systems administrator will investigate.
                      If you need immediate assistance <br> please contact us at <b>855-550-0085</b> or email us at
                        <a href="smartleadsinfo@autodatasolutions.com">smartleadsinfo@autodatasolutions.com</a>
                  </p>
                  <button style="margin: auto" class="center-block contact_submit close_button" @click="closeWindow" type="button">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "showApiError",
        data() {
            return {
                open: false
            }
        },
        computed: {
            ...mapState("dwc", ["APIError"]),
        },
        methods: {
            closeWindow() {
                this.open = false;
            }
        },
        watch: {
            "APIError": function () {
                if (this.APIError && this.APIError.response) {
                    const status = this.APIError.response.status;
                    if (status === 500) {
                        this.open = true;
                    }
                }
            }
        }
    };
</script>
<style>
    .modal-alert-msg {
      width: 40% !important;
    }
    .modal-text-body {
      padding-left: 10%;
    }
    .change_password .info_heading h3::before {
      width: 382px !important;
    }
</style>