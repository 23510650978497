import {EventEmitter} from "events";

const route_path = {
    drsDataRecording: "/compliance/drsDataRecording",
    page404: "page404",
    haccPage404: "/haccPage404",
    login: "/login",
    home: "/",
    dealerWebsiteCertification: "/dealerwebsitecertification",
    smartleads: "/smartleads",
    smartAnalytics: "/smartanalytics",
    oemSmartAnalytics: "/oemsmartanalytics",
    marketPlaceServices: "/marketPlaceServices",
    FieldSupport: "/fieldsupport",
    EnterpriseServices: "/enterpriseservices",
    leadManagement: "/leadmanagement",
    concierge: "/concierge",
    contactUs: "/contactus",
    pricecomparison: "/pricecomparison",
    drspricingcomparison: "/drspricingcomparison",
    shopper_contact: "/shoppercontact",
    profileInformationPreview: "/manageprofile",
    billingProfile: "/billingProfile/:dlCode/:editMode",
    enrolment: "/dwcpenrollment",
    admindwcpenrollment: "/admindwcpenrollment",
    resources: "/resources",
    payloadLookup: "/payloadlookup",
    payloadLookupXML: "/payloadlookupxml",
    leadsettingsPreview: "/leadsettingsPreview",
    setbudget: "/setbudget",
    logViewer: "/logviewer",
    websiteProgram: "/websiteprogram",
    websiteProgramEdit: "/websiteprogram/:dlCode",
    managePackages: "/managepackages",
    managePackagesDigitalDealer: "/managePackagesDigitalDealer",
    managesubscriptionWebProgramEdit: "/websiteprogram/managesubscription/:dlCode",
    managesubscription: "/managesubscription",
    managesubscriptionEdit: "/managesubscription/:dlCode",
    manageEnrollments: "/manageenrollments",
    dataRecording: "/compliance/dataRecording",
    editComplianceReport: "/compliance/dataRecording/:reportId",
    complianceCreateReport: "/compliance/createReport/:auditType",
    crmDataRecording: "/compliance/crmDataRecording",
    crmComplianceCreateReport: "/compliance/createCrmReport",
    viewLeads: "/viewLeads",
    leadDetailsReport: "/leadDetailsReport",
    viewLeadResponseTime: "/viewLeadResponseTime",
    viewLead: "/viewLead",
    notificationsUnsubscription: "/notificationsUnsubscription",
    editManageEnrollment: "/editManageEnrollment/:dlCode/:editMode",
    Reports: '/reports',
    video: "/video",
    privacy: '/privacy',
    newsmartanalytics: '/newsmartanalytics',
    howToEnroll: '/howToEnroll',
    DigitalCertification: '/DigitalCertification',
    DrsServices: '/DrsServices',
    addonManageEnrollment: "/addonManageEnrollment/:dlCode/:addonId"
};

class RouterService extends EventEmitter {
    getRouter(name, menuName) {
        if (name.startsWith("http")) {
            return name;
        }
        if (name.match('/')) {
            name = name.split('/').slice(-1).toString();
        }

        let routePageName = route_path[name] ? route_path[name] : route_path['page404'];
        if (menuName && menuName === 'Reports') {
            routePageName = `${route_path[menuName]}?q=${name}`;
        }

        const specialRoutes = [
            'ComplianceAggregateReport',
            'DealerComplianceReport',
            'DRSDealerComplianceReport',
            'DWCPWebproviderNonCompliantReport',
            'CRMComplianceAggregateReport',
            'CRMNonCompliantReport',
            'DRSComplianceAggregateReport',
            'DRSNonComplianceAuditReport'
        ]

        if (specialRoutes.includes(name)) {
            routePageName = `${route_path["Reports"]}?q=${name}`;
        }
        return routePageName;
    }
}
const route_service = new RouterService();
route_service.setMaxListeners(1);

export default route_service;
