<template>
  <span><br>
    <span v-if="UserDetails" class="welcome_welcome">{{ $t("app.Welcome") }}, <br v-if="lineBreak"></span>
    <span :style="UserDetails && UserDetails.userName && UserDetails.userName.length < 12 ? 'display: inline-block;' : ''">
      {{UserDetails && UserDetails.userName}}&nbsp;<span :class="chevronClass || 'welcome_chevron'"><img src="../../assets/images/svg/chevron_down.svg" class="chevron_down"></span>
    </span>
    <p v-if="UserDetails && lastLogin">
      {{ $t("app.LastLogIn") }} :
      {{new Date(UserDetails.lastLoginTime).toLocaleDateString()}}
      &nbsp;&nbsp;
      {{new Date(UserDetails.lastLoginTime).toLocaleTimeString()}}
    </p>
  </span>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    lineBreak: {
      type: Boolean,
      required: false,
      default: false
    },
    lastLogin: {
      type: Boolean,
      required: false,
      default: true
    },
    chevronClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapState('dwc', ['UserDetails'])
  },
  methods: {
  }
}
</script>
