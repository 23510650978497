<template>
    <div>
        <footer class="hidden-xs">
            <div class="footer_main_box">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-8">
                            <div class="pull-left">
                                <p>Version {{getAppVersion()}} &copy; J.D Power -
                                    <a v-if="userDetails.oemName === 'BMWCA'" href="tel:(855) 851-9181">(855) 851-9181</a>
                                    <a v-else href="tel:855-550-0085">{{ $t("app.PHONE_CONTACT_NUM") }}</a> -
                                    <a v-if="userDetails.oemName === 'Honda'" href="mailto:info@hondadigitaldealer.com">info@hondadigitaldealer.com</a>
                                    <a v-else-if="userDetails.oemName === 'Acura'" href="mailto:info@acuradigitaldealer.com">info@acuradigitaldealer.com</a>
                                    <a v-else-if="isDwcpPage" href="mailto:dwcpinfo@autodatasolutions.com">dwcpinfo@autodatasolutions.com</a>
                                    <a v-else href="mailto:customersuccess@jdpa.com">customersuccess@jdpa.com</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-4">
                            <div class="footer_links pull-right">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" @click="redirectToNew($routePath.getRouter('privacy'))">{{ $t("app.PRIVACY_POLICY") }}</a>
                                    </li>
                                    <li v-if="isTermsShow">
                                        <a href="javascript:void(0);" @click="redirectToNew($routePath.getRouter('home'))">{{ $t("app.LEGAL_TERMS") }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer class="hidden-sm hidden-md hidden-lg">
            <div class="footer_main_box">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="footer_links">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" @click="redirectToNew($routePath.getRouter('privacy'))">{{ $t("app.PRIVACY_POLICY") }}</a>
                                    </li>
                                    <li v-if="isTermsShow">
                                        <a href="javascript:void(0);" @click="redirectToNew($routePath.getRouter('home'))">{{ $t("app.LEGAL_TERMS") }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div>
                                <p>Version {{getAppVersion()}} &copy; J.D Power -
                                    <a v-if="userDetails.oemName === 'BMWCA'" href="tel:(855) 851-9181">(855) 851-9181</a>
                                    <a v-else href="tel:855-550-0085">{{ $t("app.PHONE_CONTACT_NUM") }}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {getAppVersion} from "@/config/Config";

    export default {
        props: {
            isDwcpPage: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                isTermsShow: false
            }
        },
        computed: {
            ...mapState('dwc', ['UserDetails']),
            userDetails() {
                if (this.UserDetails) {
                    return this.UserDetails;
                }
                return localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {};
            }
        },
        methods: {
          getAppVersion,
            redirectTo(path) {
                this.$router.push({path});
            },
            redirectToNew(path) {
                window.open(window.location.origin + "" + path, '_blank');
            }
        }
    }
</script>
