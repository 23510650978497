<template>

  <div :class="{'genesis-skin': isGenesisSkin, 'default-skin':!isGenesisSkin}" class="products_main_section sso-redirect">
    <div style="margin-bottom: 80px" class="row pro_heading">
      <div class="col-xs-12">
        <h3 style="text-align: center; margin-bottom: 30px">NEW SECURITY STANDARDS IN PLACE.</h3>
      </div>
    </div>
    <div style="margin-bottom: 80px" class="row">
      <div style="text-align: center" class="col-xs-12">
        <p class="inline-block text-block">
          Due to heightened security standards, you are no longer able to sign in to the SmartDigital Portal directly.
          You must use Single Sign On through <a href="#" @click="redirectToNew(ssoPortalUrl)">{{ssoPortalUrl}}</a>.
          If you have questions please contact Customer Support at <a target="_blank" :href="'tel:855-550-0085'">855-550-0085</a>
        </p>
      </div>
    </div>
    <div class="row">
      <div style="text-align: center" class="row">
        <button @click="redirectToNew(ssoPortalUrl)" class="sso-button">Single Sign On</button>
      </div>
    </div>
  </div>

</template>
<script>
import {mapState} from 'vuex'
import auth from "../../auth/authService";
import {setCookie} from '../../config/Config';

export default {
  data() {
    return {
      isTermsShow: false
    }
  },
  computed: {
    ...mapState('dwc', ['UserDetails']),
    isGenesisSkin() {
      return this.UserDetails.oemSkinName == 'genesis';
    },
    ssoPortalUrl() {
      return this.UserDetails.ssoPortalUrl;
    },
  },
  methods: {
    redirectToNew(path) {
      setCookie('ssoRedirectUrl', path, 1);
      // auth service will redirect to new path if this cookie exists
      window.console.log('Path: ' + path);
      window.console.log('Calling logOut from redirectToNew in ssoRedirectContent');
      auth.logOut();
    }
  }
}
</script>
<style>

.sso-redirect {
  padding-top: 200px;
  padding-left: 50px;
  padding-right: 50px;
}

.sso-button {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
}

.default-skin .sso-button:hover {
  background-color: #175b8d;
  color: #fff;
}

.default-skin .sso-button {
  padding: 2px;
  font-size: 20px;
  width: 150px;
  background: #8b7a6a;
  color: white;
  border: 1px solid #8b7a6a;
}

.genesis-skin .sso-button {
  padding: 20px;
  font-size: 18px;
  width: 250px;
  background: white;
  text-transform: uppercase;
  border: 1px solid #cacaca;
}

.genesis-skin .sso-button:hover {
  background: #000000;
  color: white;
}

.sso-redirect p {
  font-size: 18px;
}

.genesis-skin a {
  color: rgb(183, 138, 116);
}

.inline-block {
  display:inline-block;
}

.text-block {
  max-width: 1040px;
}
</style>