<script>
    import {getCookie} from "../../config/Config";

    export default {
        name: "filterSettingsStorageMixin",

        methods: {
            getStorageId: function () {
                let sessionId = getCookie('SSO_SESSION_ID');
                let filterName = this.$options.name.toUpperCase();

                return `FILTER_SETTINGS_${filterName}_${sessionId}`;
            },

            storeFilterSettings: function (filterObject) {
                const fId = this.getStorageId();

                const objectToSerialize = {};
                Object.assign(objectToSerialize, filterObject);
                for (var propertyName in objectToSerialize) {
                    if (objectToSerialize[propertyName] instanceof Date) {
                        objectToSerialize[propertyName] = this.transformDateToString(objectToSerialize[propertyName]);
                    }
                }

                let stringify = JSON.stringify(objectToSerialize);

                localStorage.setItem(fId, stringify);
            },

            restoreFilterSettings: function (filter) {
                const fId = this.getStorageId();
                let filterStr = localStorage.getItem(fId);
                if (!filterStr) {
                    return false;
                }

                const filterFromStorage = {};
                Object.assign(filterFromStorage, JSON.parse(filterStr));

                for (var propertyName in filterFromStorage) {
                    if (this.canBeConvertedToDate(filterFromStorage[propertyName], filter[propertyName])) {
                        filterFromStorage[propertyName] = this.transformStringToDate(filterFromStorage[propertyName]);
                    }
                }

                Object.assign(filter, filterFromStorage);
                return true;
            },

            clearAllSavings() {
                let keys = Object.keys(localStorage);
                let i = keys.length;

                while (i--) {
                    if (keys[i].startsWith('FILTER_SETTINGS_')) {
                        localStorage.removeItem(keys[i]);
                    }
                }
            },

            populateFromQuery: function (data, query) {
                let anyPresent = false;
                for (let i in data) {
                    if (query[i]) {
                        if (this.canBeConvertedToDate(query[i], data[i])) {
                            data[i] = this.transformStringToDate(query[i]);
                        } else {
                            data[i] = query[i];
                        }

                        anyPresent = true;
                    }
                }
                return anyPresent;
            },

            transformDateToString(date) {
                return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
            },

            canBeConvertedToDate(str, destination) {
                return (str && (typeof str === 'string' || str instanceof String) && (destination instanceof Date));
            },

            transformStringToDate(str) {
                const arrDate = str.split("-");
                if (arrDate.length !== 3) {
                    return new Date();
                }

                try {
                    return new Date(arrDate[0], arrDate[1] - 1, arrDate[2]);
                } catch (err) {
                    return new Date();
                }

            }
        }
    }

</script>
