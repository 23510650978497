import axios from 'axios'
// import qs from 'qs';
import { store } from '../store'
// before a request is made start the nprogress
axios.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    return config;
});
// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});
export const getFaqServiceData = () =>
new Promise((resolve, reject) => {
    const config = {
        'dataType': 'json',
        'withCredentials': true
    };
    axios.get('/mocdata/faq.json', config)
        .then(response => resolve(response))
        .catch((error) => {
            store.dispatch('dwc/setLoading', false);
            reject(error)
        });
});
export const getProductServiceData = (oem) =>
    new Promise((resolve, reject) => {
        const config = {
            'dataType': 'json',
            'withCredentials': true
        };

        const oemName = oem.toLowerCase();
        let mockDataFileName = 'common';
        if (/acura|bmw|bmwca|bmwmc|genesis|hyundai|maserati|mazda|mini|honda/.test(oemName)) {
            mockDataFileName = oemName.replace(/\s+/g,"_");
        }

        const url = `/mocdata/products/${mockDataFileName}.json`;
        axios.get(url, config)
            .then(response => resolve(response))
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });
