import * as moment from "moment-timezone";

export default {
    getYearAgo() {
        let d = new Date();
        let pastYear = d.getFullYear() - 1;
        d.setFullYear(pastYear);
        if (d.getMonth() === 11) {
            return new Date(d.getFullYear() + 1, 0, 1);
        } else {
            return new Date(d.getFullYear(), d.getMonth() + 1, 1);
        }
    },
    formatYYYYMMDD(date) {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    },
    formatMMDDYYYY(date) {
        return `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`;
    },
    format01_MM_YYYY(date) {
        return `01-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    },
    formatLastDay_MM_YYYY(date) {
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return `${(lastDay.getDate())}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    },
    getYearMonth(date) {
        return date.toLocaleString('en-us', {month: 'short', year: 'numeric'});
    },
    getQuarterMonthAgo() {
        let d = new Date();
        d.setMonth(d.getMonth() - 2);
        return new Date(d);
    },
    toDate(str) {
        if (!str) {
            return null;
        }

        if (str instanceof Date) {
            return str;
        }

        const arr = str.split('-');
        if (arr.length < 3) {
            return null;
        }

        return new Date(arr[0], arr[1] - 1, arr[2]);
    },
    monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    },
    addDays(date, days) {
        return new Date(date.getTime() + ((24 * 60 * 60 * 1000) * days));
    },
    addMonths(date, months) {
        const newDate = new Date(date.getTime());
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    },
    daysDiff(d1, d2) {
        return Math.round((d2 - d1) / (1000 * 60 * 60 * 24));
    },
    getCurrentServerDate() {
        const centralDateString = moment(new Date(), null).tz("US/Central").format('YYYY-MM-DD');
        const arr = centralDateString.split('-');
        const centralDate = new Date(arr[0], arr[1] - 1, arr[2], 0, 0, 0);
        return centralDate;
    },
    getCurrentServerDateAtEnd() {
        const currentDate = this.getCurrentServerDate();
        currentDate.setHours(23, 59, 59);
        return currentDate;
    },
    getAllowedDateRangeFrom(numberOfDays, toDate) {
        let tmpDate = this.getCurrentServerDate();
        tmpDate.setDate(tmpDate.getDate() - numberOfDays);

        return {
            from: toDate,
            to: tmpDate
        };
    },
    getAllowedDateRangeTo(fromDate) {
        const result = {
            from: this.getCurrentServerDateAtEnd(),
            to: fromDate
        };

        return result;
    }
}