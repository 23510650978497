import supportedLocales from "@/config/supported-locales"

export function userLocale() {
      let user = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {};
      let userLang = user != null ? user.language : null;
      let locale = null;
      if (userLang != null) {
        if (userLang == "FRENCH") {
            locale = "fr";
        }
        if (userLang == "ENGLISH") {
            locale = "en";
        }
      }
      return locale;
}

export function mapLocale(code) {
    if (code == "fr") {
        return "FRENCH";
    }
    return "ENGLISH";
}

export function getSupportedLocales() {
  let annotatedLocales = []

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale)
}
