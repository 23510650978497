export function getAppVersion() {
    return require('../../package.json').version.replaceAll('-', '.');
}

export function getCookie(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function delete_cookie(name, domain) {
    document.cookie = name + "=;" +
        ((domain) ? ";domain=" + domain : "") +
        '; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export function showError(id, errors) {
    let isActive = false;
    if (errors.length > 0) {
        let errorData = (errors.filter(err => err.id === id));
        isActive = errorData.length > 0 && errorData[0].isActive;
    }
    return isActive;
}
export function showErrorText(id, errors) {
    let error = false;
    if (errors.length > 0) {
        let errorData = errors.filter(err => err.id === id);
        error = errorData.length > 0 && errorData[0].error;
    }
    return error;
}
export function sortByKey(arr, key) {
    return arr && arr.sort((a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) { return -1; }
        if (a[key].toLowerCase() > b[key].toLowerCase()) { return 1; }
        return 0;
    });
}
export function sortByKeyAcura(arr, key) {
    return arr && arr.sort((a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) { return -1; }
        if (a[key].toLowerCase() > b[key].toLowerCase()) { return 1; }
        return 0;
    });
}
export function sortByKeyId(arr, key) {
    return arr && arr.sort((a, b) => {
        if (a[key] < b[key]) { return -1; }
        if (a[key] > b[key]) { return 1; }
        return 0;
    });
}

export const offerKinds = {
    'BUNDLE': 0,
    'PACKAGE': 1,
    'ADDITIONAL': 2
};

export const offerKindsNames = {
    'BUNDLE': 'Full Service',
    'PACKAGE': 'Mix & Match',
    'ADDITIONAL': 'Additional'
};

export const packageStatuses = {
    'SUBSCRIPTION_PENDING': 'Subscription Pending',
    'SUBSCRIBED': 'Subscribed',
    'UNSUBSCRIBED': 'Unsubscribed',
    'TERMINATION_PENDING': 'Termination Pending',
    'CANCELLED': 'Cancelled'
};

export const HONDA_DWC_OFFER_TYPE_ID = {
    DWS: 5, // Website
    DIGAD: 6,
    FULL_SERVICE: 7,
    DRS: 8,
    REP: 9,
    CALL: 10,
    TRADEIN: 11,
    CREATIVE: 15,
    LEAD: 16,
    CHAT: 17,
    EVHUB:1034
};

export const ACURA_DWC_OFFER_TYPE_ID = {
    DWS: 19,
    DIGAD: 20,
    FULL_SERVICE: 21,
    DRS: 22,
    REP: 23,
    CALL: 24,
    TRADEIN: 25,
    CREATIVE: 26,
    CHAT: 27,
    LEAD: 28,
    EVHUB:1035
}

export function sortSubscriptionOptions(arr) {
    if (!arr) {
        return arr;
    }
    let array = arr.sort((a, b) => {
        if (a.offerKind !== b.offerKind) {
            // move ADDITIONAL to the bottom
            return offerKinds[a.offerKind] - offerKinds[b.offerKind];
        } else { // within same kind sort by display priority
            return a.dwcPriority - b.dwcPriority;
        }
    });

    let lastKind = null;
    for (let i = 0; i < array.length; i++) {
        let option = array[i];
        if (option.offerKind !== lastKind) {
            option.firstOfKind = option.offerKind;
            lastKind = option.offerKind;
        }
    }
    return arr;
}

export function replaceOemCustomLabelPlaceholders(UserDetails, text) {
    if (UserDetails && text) {
        const labels = UserDetails.customLabels;
        Object.entries(labels).forEach(([key, value]) => {
            text = text.replaceAll(`{${key}}`, value);
            text = text.replaceAll(`{${key.toLowerCase()}}`, value.toLowerCase());
        });
    }
    return text;
}

export const customSystemData = {
    environmentsKeyMonitor: {
        dev: "ca6493618064fc20de877598843ad6f1",
        qa: "8e4fbd50489e420b696be5c5eb4746de",
        prod: "c022ed9105b740bc43111b619e947bc0"
    }
};

(function(w,d,s,r,k,h,m){
    if(w.performance && w.performance.timing && w.performance.navigation) {
        w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)};
        h=d.createElement('script');h.async=true;h.setAttribute('src',s+k);
        d.getElementsByTagName('head')[0].appendChild(h);
        (m = window.onerror),(window.onerror = function (b, c, d, f, g) {
            m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push(["captureException",g]);})
    }
})(window,document,'//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=','s247r',
    (window.location.href.indexOf("devstage") > -1) ? customSystemData.environmentsKeyMonitor.dev : (window.location.href.indexOf("qa") > -1) ? customSystemData.environmentsKeyMonitor.qa : customSystemData.environmentsKeyMonitor.prod);

