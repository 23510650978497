<template>
    <div :class="this.errorClass && showError ? this.errorClass : ''">
        <slot></slot>
        <label class="required_validation" v-show="showError">
            {{ showErrorText }}
        </label>
    </div>
</template>

<script>
    export default {
        name: 'form-field',
        props: {
            id: {
                type: String,
                required: true
            },
            errors: {
                type: Array,
                required: true
            },
            errorClass: {
                type: String,
                default: '',
            }
        },
        computed: {
            error() {
                if (this.errors && this.errors.length > 0) {
                    let errorData = this.errors.filter(err => `${err.id}` === `${this.id}`);
                    return errorData.length > 0 && errorData[0];
                }
                return null;
            },
            showError() {
                return this.error && this.error.isActive;
            },
            showErrorText() {
                return this.error && this.error.error;
            }
        },
        methods: {}
    };
</script>
