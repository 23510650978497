export const customMessages = {
    Login_Form: {
        userName: "Username required.",
        password: "Password required."
    },
    Forgot_Pass: {
        Comman_Msg: 'Please enter atleast one value.',
        User_Email: 'Valid email required.',
        Success_Message: 'Further instructions to restore you password have been sent to your email. Please check your email and follow the instructions.'
    },
    Enrolment_Form: {
        Name:"Authorized Person's Name is required.",
        Email: "Email is required.",
        Email_Valid: "Valid email is required.",
        Dealer_Code: "Dealer Code is required.",
        Contact_Number: "Contact Number is required.",
        Dealer_Name: "Dealer Name is required.",
        Dealer_Website_URL: "Dealer Website URL is required.",
        Dealer_Website_Valid_URL: "Website URL must be valid.",
        dealer_website_provider: "Dealer Website Provider required.",
        dealer_website_package: "Dealer Website Package required.",
        Digital_Retailing_Provider: "Digital Retailing Provider required.",
        Digital_Retailing_Package: "Digital Retailing Package required.",
        term_condition_check: "Term and Condition check required.",
        phone: "Authorized Person's Phone is required.",
        auth_person_email: "Authorized Person's Email is required.",
        auth_person_email_valid: "Valid Authorized Person's Email is required.",
        billingContactName: "Billing Person's Name is required.",
        billingContactPhone: "Billing Person's Phone is required.",
        billingContactEmail: "Billing Person's Email is required.",
        Valid_Phone: "Please enter valid Phone Number in format: xxx-xxx-xxxx or (xxx) xxx-xxxx",
        email_valid: "Please enter a valid email",
        ProviderMsg: "Provider Name is required",
        ProviderPkg: "Package Name is required",
        Please_Select_Option: "Please choose either option",
        Please_Select_Any: "Please select at least one enrollment option",
        pending_go_live_packages: "Go-Live date is already set for some of your packages. Please contact Support if you wish to change your subscriptions.",
        term_condition_check_msg: "Terms and Condition should be selected."
    },
    SetBudget: {
        BudgetNextMonthApplyMsg: "Budget changes will apply from the next month",
        BudgetInfoMsg: "When you click 'Save', the budget increase will be effective immediately, and your balance will reflect the applicable increment for this month",
        BudgetDecreaseInfoMsg: "When you click 'Save', the budget decrease will be effective immediately, and your balance will reflect the applicable increment for this month",
        BudgetChangeAdmin: "When you click 'Save', the changes will be effective immediately",
        budgetMsg: "Please enter valid value for budget.",
        ViewBudgetFor: "View Budget name required.",
        ProgramName: "Program name required."
    },
    ProfileInformation: {
        CardNumberEmpty: "This field is required.",
        CardNumber: "Please enter a valid credit card number.",
        MonthYear: "Month/Year required.",
        SecurityCodeEmpty: "This field is required.",
        SecurityCode: "Invalid Security code. Please enter 3- or 4-digit number"
    },
    Country_ZipCode: {
        usa: "Invalid ZIP format. Please enter ZIP code in format: nnnnn or nnnnn-nnnn",
        us: "Invalid ZIP format. Please enter ZIP code in format: nnnnn or nnnnn-nnnn",
        canada: "Invalid ZIP format. Please enter ZIP code in format: ANA NAN",
        ca: "Invalid ZIP format. Please enter ZIP code in format: ANA NAN",
    },
    Lead_Settings: {
        Preferences: {
            leadFormat: "Lead Format required.",
            Email: "Email required.",
            Email_Valid: "Email format invalid.",
            Updated: "Lead destination updated.",
            Deleted: "Lead destination deleted."
        }
    },
    ChangePassword: {
        IsPermissionMessage: 'You do not have permission to change password.',
        SuccessMessage: "Password updated successfully.",
        CuurrentPassword: "Current Password is mandatory",
        NewPassword: " New Password is mandatory",
        ConfirmPassword: "Confirm Password is mandatory",
        PasswordMatch: "Password must match",
        PasswordStringth: "New Password does not fullfill below written conditions."
    },
    ContactUs: {
        Name: 'This field is required.',
        EmailEmpty: "This field is required.",
        Email: "The email address does not appear to be valid.",
        Message: 'This field is required.',
        Phone: 'Please enter valid phone number in format: xxx-xxxxxxx or (xxx) xxx -xxxx'
    }
}; 