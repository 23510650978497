import axios from 'axios'
import qs from 'qs';
import {store} from '../store'
import auth from "../auth/authService";

const AXIOS = axios.create({
    baseURL: '/api/sso',
    timeout: 120000
});

export const AXIOS_1 = axios.create({
    baseURL: '/',
    timeout: 120000
});

const AXIOS_Export = axios.create({
    baseURL: '/',
    timeout: 120000
});

const AXIOS_Background = axios.create({
    baseURL: '/',
    timeout: 120000
});

// before a request is made start the nprogress
AXIOS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        // 'content-type': 'application/x-www-form-urlencoded'
    };
    return config;
});
// before a response is returned stop nprogress
AXIOS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

// before a request is made start the nprogress
AXIOS_1.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        // 'content-type': 'application/x-www-form-urlencoded'
    };
    return config;
});
// before a response is returned stop nprogress
AXIOS_1.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});
AXIOS_Export.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        // 'content-type': 'application/x-www-form-urlencoded'
    };
    return config;
});
AXIOS_Export.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});
AXIOS_Background.interceptors.request.use(config => {
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`
    };
    return config;
});

export const initCommonInterceptors = () => {
    auth.addInterceptors(AXIOS);
    auth.addInterceptors(AXIOS_1);
    auth.addInterceptors(AXIOS_Export);
    auth.addInterceptors(AXIOS_Background);
};
export const getOEMsAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/oems`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getOfferProvidersAPI = (data) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/dwc/offerProviders`;
        if (data.oemId) {
            url += `?oemId=${data.oemId}`;
        }
        if (data.drs === true) {
            if (data.oemId) {
                url += `&drs=1`;
            } else {
                url += `?drs=1`;
            }
        }

        AXIOS_1.get(url)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getHeaderMenuDataAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS.get('/user/allowedComponents')
            .then(response => resolve(response))
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getUserInformation = () =>
    new Promise((resolve, reject) => {
        AXIOS.get('/user/userData')
            .then(response => resolve(response))
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getAllAddonDetailsAPI = (queryString) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/getAddons${queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getUsersToImpersonateApi = (queryString) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/impersonate/users${queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const impersonateUserByLoginAPI = (data) =>
    new Promise((resolve, reject) => {
        const requestBody = data;
        AXIOS_1.post(`/api/portal/impersonate`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const deImpersonateUserByLoginAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.delete(`/api/portal/impersonate`,
        )
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getSelectedDealerProgramDetailsAPI = (dealerCode) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dealerPrograms/${dealerCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getSelectedDealerBudgetDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dealerProgramBudget/${data.dealerCode}/${data.programId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const updateSelectedDealerBudgetDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const requestBody = {
            newBudget: data.newBudget
        };
        AXIOS_1.post(`/api/portal/dealerProgramBudget/${data.dealerCode}/${data.programId}`,
            qs.stringify(requestBody))
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getSelectedDealerLeadSettingsDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/${data.dealerCode}/${data.programId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const saveDealersLeadCoverageAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/leadProgramSettings/adjustCoverage/save`,
            data, {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const exportAdjustCoverageZipAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/leadProgramSettings/adjustCoverage/exportZip?${data}`, {
            responseType: 'blob'
        })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const downloadZipBulkImportAPI = (data) =>
    new Promise((resolve, reject) => {
        const path = data.template ? `zipCodeBulkImportResultExportTemplate` : `zipCodeBulkImportResultExport`;
        const dlCode = data.dealerCode;
        AXIOS_Export.get(`/api/portal/leadProgramSettings/${path}/${dlCode}`, {
            responseType: 'blob'
        })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getZipCodeStringByRadiousAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/leadProgramSettings/adjustCoverage/getZipCodesStringByRadius?${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getAdjustCoverageLeadSettingsDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/adjustCoverage/${data.dealerCode}/${data.programId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getDealersLeadCoverageAPI = data =>
    new Promise((resolve, reject) => {
        const requestBody = data;
        AXIOS_1.post('/api/portal/getDealersLeadCoverage', requestBody, {
            headers: {'content-type': 'application/json'}
        })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    })

export const getRadiusCityAPI = data =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/adjustCoverage/getCityZipList?centralZip=${data.centralZip}&radius=${data.radius}&term=${data.term}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            })
    })
export const getCityZipListAPI = data =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/adjustCoverage/getCityZipList?centralZip=${data.centralZip}&radius=${data.radius}&term=${data.term}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            })
    })
export const getPreferencesLeadSettingsDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/preferences/${data.dealerCode}/${data.programId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const savePreferencesLeadSettingsDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const requestBody = data.newDest;
        AXIOS_1.post(`/api/portal/leadProgramSettings/savePreferences/${data.dealerCode}/${data.programId}`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getVehicleManagementAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/vehicleManagement/${data.dealerCode}/${data.programId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const saveVehicleManagementAPI = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data.vehicleData;
        AXIOS_1.post(`/api/portal/leadProgramSettings/saveVehicleManagement/${data.dealerCode}/${data.programId}`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
// ----------------------------------------------------------
export const getManageEnrolmentFilterOptionsAPI = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data;
        AXIOS_1.post(`/api/portal/enrollments/filter-options`,
            requestBody,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const searchManageEnrolmentDataAPI = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data.payload;
        AXIOS_1.post(`/api/portal/enrollments/search${data.queryString}`,
            requestBody,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//Manage Profile Code Starts Here - Get Data
export const getUserProfileDetailsAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/profileInformation`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const saveUserProfileDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data;
        AXIOS_1.post(`/api/portal/profileInformation`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getBillingProfileDetailsAPI = (code) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/enrollments/billing-profiles/${code.dealerCode}`;
        if (code.programId) {
            url = `/api/portal/enrollments/billing-profiles/${code.dealerCode}?programId=${code.programId}`;
        }
        AXIOS_1.get(url)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const saveBillingProfileAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollments/billing-profiles/${data.dealerCode}/save`,
            data.requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const activateBillingProfileAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollments/billing-profiles/${data.dealerCode}/activate?programId=${data.programId}`,
            data.requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

//Manage Profile Code Ends Here

//Manage Profile contact Code Starts Here - Get Data
export const getUserProfileContactDetailsAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/profileContactDetails`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const saveUserProfileContactDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data;
        AXIOS_1.post(`/api/portal/profileContactDetails`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//Manage Profile contact Code Ends Here
export const exportManageEnrolmentSearcheddataAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/enrollments/export${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getOemProgramAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/enrollments/list/get-oem-programs${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getProgramAddonsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/enrollments/list/get-addons${data}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getLeadsFilterOptionsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leads/filter-options`, {params: data})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getLeadsSearchAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/leads/search`,
            data.filterBody,
            {
                params: {...data.pageable},
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//Payload Lookup Code Starts Here
//Get Page
export const exportPayloadResultsXLS_API = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data.payload;
        AXIOS_Export.get(`/api/portal/payload-lookup/download${data.queryString}`,
            {
                params: {...requestBody},
                responseType: 'blob'
            },
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

//Get Log Viwer Page With Params
export const getPageLogViewerWithParams = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/log-viewer/get-page`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

//Search Log Viewer
export const callLogViewerSearch = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data.payload;
        AXIOS_1.post(`/api/portal/log-viewer/search${data.queryString}`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });


//Get Page With Params
export const getPagePayloadLookupWithParams = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/payload-lookup/get-page${data.queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

//Search
export const callPayloadLookupSearch = (data) =>
    new Promise((resolve, reject) => {
        let requestBody = data.payload;
        AXIOS_1.post(`/api/portal/payload-lookup/search${data.queryString}`,
            requestBody,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

// Get Incoming/Outgoing Failure Details
export const callGetPayloadFailureDetails = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/payload-lookup/fail-details${data.queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

//Open Incoming/Outgoing Payload XML
export const callPayloadLookupPayloadXML = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/payload-lookup/payload-xml${data.queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

//Download Incoming/Outgoing Payload XML
export const callPayloadLookupDownloadPayloadXML = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/payload-lookup/payload-xml${data.queryString}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

// API for change password
export const changePasswordPermissionAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/changePassword`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const changePasswordAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/changePassword`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
// API for change password - End

export const changeUserLanguageAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/changeUserLanguage?languageCode=${data}`,
            null,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

// API for manage website and packages

export const getDealerEnrollmentData = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/manageDealerPackages/dealerEnrollmentData?dlCode=${data.dlCode ? data.dlCode : ''}`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getManageWebsiteProgramsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/dwc/websiteprogram/async/search?page=` + data.page,
            data,
            {headers: {'content-type': 'application/json'}})

            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getDealerSubscriptionsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dwc/websiteprogram/async/dealerSubscriptions?addonId=${data.addonId}&dealerCode=${data.dealerCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getRegionListFilterAPI = (filterData) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/dwc/websiteprogram/async/regions-website?oemId=` + filterData.oemId)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getDistrictListFilterAPI = (filterData) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/dwc/websiteprogram/async/districts-website?oemId=` + filterData.oemId + `&regionCode=` + filterData.regionCode)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const setManageProgramActionAPI = (data) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/dwc/websiteprogram/async/dealersubscriptions/${data.methodName}`;
        let dataEncoded = `dealerCode=${data.dealerCode}&subscriptionMappingId=${data.mappingId}&actionName=${data.methodName}&subPackageStatus=${data.state}&skipDealerCommunication=${data.skipDealerCommunication ? '1' : '0'}&applyToAllPackages=${data.applyToAllPackages ? '1' : '0'}&scheduledDate=${encodeURIComponent(data.scheduledDate)}`;
        AXIOS_1.post(url,
            dataEncoded,
            {headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getManageWebsiteProgramsFilterAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/dwc/websiteprogram/async/filter-options?dealerCode=${data.dealerCode ? data.dealerCode : ''}&oemId=${data.oemId}&offerProvider=${data.providerId ? data.providerId : ''}&offerType=${data.offerType ? data.offerType : ''}`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getChangeableSubscriptions = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/manageDealerPackages/changeableSubscriptionsList?addonId=${data.addonId}&dealerCode=${data.dealerCode ? data.dealerCode : ''}`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const postChangeSubscriptions = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/manageDealerPackages/changeSubscriptions?dealerCode=${data.dealerCode ? data.dealerCode : ''}&offerProviderId=${data.offerProviderId}&offerTypeId=${data.offerTypeId}&optionalAddonIds=${data.optionalAddonIds}&packageId=${data.packageId}`, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getSubscriptionOptions = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/manageDealerPackages/subscriptionOptions?addonId=${data.addonId}&dealerCode=${data.dealerCode ? data.dealerCode : ''}`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const postManageSubscriptions = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/manageDealerPackages/updateSubscriptions?addonId=${data.addonId}&dealerCode=${data.dealerCode ? data.dealerCode : ''}`,
            data,
            {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

//----------- Lead Viewer Request Credit and Download ADF API start Here --------------
export const getRequestCreditReasonAPI = (lead) =>
    new Promise((resolve, reject) => {
        const leadUID = lead.leadUid;
        const dealerCode = lead.dlCode;
        AXIOS_1.post(`/api/portal/leads/requestCreditForm`,
            {
                leadUID: leadUID,
                dealerCode: dealerCode
            },
            {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const postRequestCreditReasonAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/leads/requestCredit?dealerCode=${data.dealerCodeForRequest}`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const downloadLeadsADFFormatAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.get(`/api/portal/leads/downloadlead?leadId=${data.leadId}&sourceId=${data.sourceId}&entityName=${data.entityName}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const findDispositionDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leads/findDispositionDetails`,
            {
                params: {...data},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getLeadRecordAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leads/getRecord`,
            {
                params: {leadUid: data.leadUid, sourceLeadId: data.sourceLeadId, entityName: data.entityName},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getLeadResponseTimeAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leads/getResponseTime`,
            {
                params: {id: data.id, leadUid: data.leadUid, sourceLeadId: data.sourceLeadId},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const notificationsUnsubscriptionAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/notifications/unsubscription`,
            {
                params: {id: data.id},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//----------- Lead Viewer Request Credit and Download ADF API End Here --------------

//----------------
export const deletePreferencesLeadSettingsDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const requestBody = data.newDest;
        AXIOS_1.delete(`/api/portal/leadProgramSettings/deletePreferences/${data.dealerCode}/${data.programId}`,
            {data: requestBody},
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//--------------

//---------------------- Edit Manage Enrollment Start ----------
export const getDealerEnrollmentDetailsAPI = (dlCode) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/enrollments/dealer/${dlCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getAddonManageEnrollmentAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dealers/smartLeadsServices/view`,
            {
                params: {...data},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getSmartleadServiceAddonCustomParameterDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dealers/smartLeadsServices/addonCustomParametersIFrame`,
            {
                params: {...data},
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getSmartleadServiceAddonModelsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/dealers/smartLeadsServices/getModelProposal?modelContains=${data.modelContains}&modelNotContains=${data.modelNotContains}&make=${data.make}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const updateAddonCustomParametersAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/dealers/smartLeadsServices/updateAddonCustomParameters`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const saveAddonParametersAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/dealers/smartLeadsServices/saveAddonParams`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const updateDealerEnrollmentDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollments/dealer/${data.dlCode}`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const otherUpdateDealerEnrollmentDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollments/dealer/${data.dlCode}/${data.type}?inactivationType=${data.inactivationType}&stateChangedReason=${data.stateChangedReason}`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const otherUpdateDealerBusinessTimeEnrollmentDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollments/dealer/${data.dlCode}/update-properties`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const updateProgramStatusAPI = (data) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/enrollments/programs/${data.action}?dealerCode=${data.dealerCode}&programId=${data.programId}&programChangeStateReason=${data.programChangeStateReason}`;
        if (data.programId) {
            url = `/api/portal/enrollments/programs/${data.action}?dealerCode=${data.dealerCode}&programId=${data.programId}&programChangeStateReason=${data.programChangeStateReason}&programId=${data.programId}`;
        }
        AXIOS_1.post(url,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => {
                return resolve(response);
            })
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });
export const getAddonProgramManageEnrollmentAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/enrollment/manageEnrollments/${data.action}?dealerCode=${data.dealerCode}&addonDealerMappingIds=${data.addonDealerMappingIds}&scheduledDate=${data.scheduledDate}`
        )
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getProductPricingDetailAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dwc/addonSubscription/prices/${data.api}?oemId=${data.oemId}&pageType=${data.pageType}&primaryOffers=${data.primaryOffers}${data.required ? '&required=' + data.required : ''}`
        )
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });

//---------------------- Edit Manage Enrollment End ----------
//Contact Us Code Starts Here - API Call
export const submitContactFormAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/contactUs`,
            data,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
//Contact Us Code Ends Here

export const updatePortalNotificationSubscriptionAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/notifications/subscription/all`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

// Resources code start here
export const deleteResourceAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.delete(`/api/portal/resources/delete/${data.id}`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getResourcesFilterOptionsAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/resources/filter-options`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });
    });

export const getFilteredProductsList = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/resources/filterResourceProduct?componentId=${data.componentId || ''}&page=${data.page}&size=${data.size}&oemId=${data.oemId || ''}&categoryId=${data.categoryId || ''}&documentType=${data.documentType || ''}&language=${data.language || ''}&resourceName=${data.resourceName || ''}`)
            .then(response => resolve(response)
            )
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            });

    });

export const getOfferProvidersPDFAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dwc/addonOfferProvider/pdfUrl?providerName=${data.providerName}&offerTypeName=${data.offerTypeName}&oemName=${data.oemName}&pdfCount=${data.pdfCount}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getCrmAPI = (oemId) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/dwc/crm`;
        if (oemId) {
            url += `?oemId=${oemId}`;
        }
        AXIOS_1.get(url)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getComplianceCategoriesAPI = (data) =>
    new Promise((resolve, reject) => {
        let url = `/api/portal/dwc/complianceCategories`;
        url += `?auditType=${data.complianceAuditType}`;
        if (data.oemMasterId) {
            url += `&oemId=${data.oemMasterId}`;
        }
        AXIOS_1.get(url)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getLeadSalesDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/leadProgramSettings/leadSales/${data.dealerCode}/${data.leadCategory}`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getDealerDataByDistrictAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/leadProgramSettings/other-dealers/${data.dealerCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getManageWebsiteProgramsReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_Export.post(`/api/portal/dwc/websiteprogram/async/export-csv?page=` + data.page,
            data,
            {
                responseType: 'blob'
            },
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const updateDealerSubscriptionCustomParamsAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.post(`/api/portal/dwc/websiteprogram/async/dealerSubscriptions/customParams?dealerCode=${data.dealerCode}`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });

export const getProviderInitialPageDataAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS_1.get(`/api/portal/dwc/addonOfferProvider/providerInitialPageData?id=${data.id}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
