<template>
  <div id="app" :class="globalOemClass">
    <showError></showError>
    <showApiError></showApiError>
    <loader :isLoading="isLoading"></loader>
    <error/>
    <router-view/>
  </div>
</template>

<script>
import Error from "./components/Error";
import loader from "./components/Loader";
import showError from './components/ShowSessionExpiredError.vue';
import showApiError from './components/ShowApiError.vue';
import {mapState} from 'vuex';

export default {
  components: {
    Error,
    loader,
    showError,
    showApiError
  },
  computed: {
    ...mapState('dwc', ['isLoading', 'UserDetails', 'APIError']),
    globalOemClass() {
      if (!this.UserDetails) {
        return window.localStorage.oemSkinName || '';
      }
      const oemSkinName = this.UserDetails.oemSkinName || '';
      // Save to local storage to avoid UI flickering OFF and ON to skinned theme on page refresh
      // (happens because UserDetails were not received from the server yet)
      window.localStorage.oemSkinName = oemSkinName;
      return oemSkinName;
    }
  },
  async created() {
  }
}
</script>
<style>
@import './assets/css/style.css';
@import './assets/css/modal.css';
@import './assets/css/honda.css';
@import './assets/css/responsive.css';
</style>
<style v-if="globalOemClass === 'genesis'" >
@import './assets/css/genesis-images.css';
</style>
<style v-if="globalOemClass === 'acura'" >
@import './assets/css/acura-images.css';
</style>
<style v-if="globalOemClass === 'infiniti'" >
@import './assets/css/infiniti-images.css';
</style>
<style v-if="globalOemClass === 'genesis'" lang="scss">
@import './assets/css/genesis-skin.scss';
</style>
<style v-if="globalOemClass === 'acura'" lang="scss">
@import './assets/css/acura-skin.scss';
</style>
<style v-if="globalOemClass === 'infiniti'" lang="scss">
@import './assets/css/infiniti-skin.scss';
</style>