<template>
    <div class="message_icon" @click="goToContactPage">
        <a href="javascript:void(0);"><img src="../assets/images/svg/feedback_icon.svg" class="img-responsive" alt="Feedback Form" title="Feedback Form"></a>
    </div>
</template>
<script>
export default {
    name: 'feedback',
    methods: {
        goToContactPage() {
            this.$router.push({ path: 'contactUs' });
        }
    }
}
</script>
