<template>
  <div
      :class="[isVisible || innerVisible ? 'open'  : 'close']"
      class="modal fade simple-modal opacity-1"
      role="dialog"
  >
    <div class="covering_div"></div>
    <div class="modal-dialog no_transform" :class="modalClass">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" @click="modalClose" class="close" data-dismiss="modal" style="display: block;">×</button>
          <div style="pointer-events: none" class="info_heading">
            <h4>{{modalTitle}}</h4>
          </div>
        </div>
        <div class="modal-body">
          <form>
            <div class="changepwd_form" :class="formClass">
              <div class="form-group">
                <slot></slot>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="warning-row" v-if="warningMessage">
            <span class="red" v-html="warningMessage"></span>
          </div>
          <div class="buttons-row">
            <button @click="modalSubmit" :disabled="isOkDisabled" v-if="!isOkHidden" class="contact_submit" type="button">{{okBtnText || $t("app.OK")}}</button>
            <button @click="modalClose" :class="[isOkHidden ? 'middle'  : '']" class="contact_submit" type="button">{{ cancelBtnText || $t("app.Cancel") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id : {
      type: String,
      required: false
    },
    isVisible: {
      type: Boolean,
      required: false
    },
    modalTitle: {
      type: String,
      required: false
    },
    modalClass: {
      type: String,
      required: false,
      default: ''
    },
    isOkDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isOkHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    okBtnText: {
      type: String
    },
    cancelBtnText: {
      type: String
    },
    formClass: {
      type: String,
      required: false,
      default: ''
    },
    warningMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  name: "simple-modal",
  data() {
    return {
      innerVisible: this.isVisible
    };
  },
  watch: {
      	isVisible: {
      	    immediate: true,
      	    handler(newVal, oldVal) {
            if (newVal) {
                $("body").css({'overflow-y':'hidden'});
            }
            }
        }
  },
  methods: {
    modalClose() {
      $("body").css({'overflow-y':'visible'});
      this.$emit('modalClose', this.data);
      this.innerVisible = false;
    },
    modalSubmit() {
      $("body").css({'overflow-y':'visible'});
      this.$emit('modalSubmit', this.data);
      this.innerVisible = false;
    },
    show(data) {
      $("body").css({'overflow-y':'hidden'});
      this.innerVisible = true;
      this.data = data;
    },
    hide() {
      $("body").css({'overflow-y':'visible'});
      this.innerVisible = false;
    }
  },
};
</script>
<style>
.simple-modal .info_heading {
  margin-top: 16px;
}

.simple-modal .modal-dialog {
  width: 85%;
  margin: 10vh auto 5vh;
  z-index: 9999;
}

.simple-modal .modal-content {
  border-radius: 10px;
  border: none;
}

.simple-modal .modal-header {
  background: #175c8e;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  border-radius: 8px 8px 0 0;
}

.simple-modal .modal-body {
  border-radius: 0 0 8px 8px;
  max-height: calc(100vh - 15vh - 196px);
  min-height: 80px;
  overflow-y: auto;
}

.simple-modal .close {
  color: #fff;
  opacity: 1;
  font-size: 50px;
  font-weight: 200;
  outline: none;
}

.simple-modal select,
.simple-modal textarea {
  width: 100%
}

.simple-modal .buttons-row {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.simple-modal .buttons-row button {
  display: inline-block;
  margin: 20px 0 15px;
  width: auto;
}

.simple-modal .buttons-row button:first-child {
  float: left;
}

.simple-modal .buttons-row button:last-child {
  float: right;
}

.simple-modal .buttons-row button.middle {
  float: none;
  margin: 0 30%;
}

.simple-modal .modal-footer .warning-row {
  text-align: center;
  padding: 0 10% 0 10%;
}

@media (min-width: 706px) {
  .simple-modal .modal-dialog {
    width: 600px;
  }
}

@media (min-width: 2100px) {
  .simple-modal .modal-dialog {
    width: 35%;
  }
}

@media (max-height: 500px) {
  .simple-modal .modal-dialog .modal-footer {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .simple-modal .buttons-row button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-height: 370px) {
  .simple-modal .modal-dialog .modal-footer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .simple-modal .buttons-row button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
