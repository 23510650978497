import axios from 'axios'
import {store} from '../store'
import auth from "../auth/authService";

const AXIOS = axios.create({
    baseURL: '/',
    timeout: 120000
});

// before a request is made start the nprogress
AXIOS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
    };
    return config;
});
// before a response is returned stop nprogress
AXIOS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});


export const initEnrollmentInterceptors = () => {
    auth.addInterceptors(AXIOS);
};

export const getDealerEnrollmentDataAPI = (data) =>
    new Promise((resolve, reject) => {
        let urlParms = `dealerCode=${encodeURIComponent(data.dealerCode)}`;
        if (data.addonId) {
            urlParms = urlParms + `&addonId=${data.addonId}`;
        }
        if (data.providerId) {
            urlParms = urlParms + `&providerId=${data.providerId}`;
        }
        AXIOS.get(`/api/portal/enroll/dwcp?${urlParms}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getDealerEnrollmentDataInfoAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/enroll/dwcpInfo`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getManageEnrolmentDownloadManagerDataAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/enrollments/downloadManagerData`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const postDealerEnrollmentDataAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/enroll/dwcp`, data, { headers: { 'content-type': 'application/json' } })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const sendDealerProviderInterestNotificationAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/enroll/dealerProviderInterestNotification`, data, { headers: { 'content-type': 'application/json' } })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
