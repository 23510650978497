import {EventEmitter} from "events";
import {delete_cookie, getCookie} from '../config/Config';
import {implicitAuthorize, reGenerateTokenAPI} from '../services/token-api';
import {store} from '../store';
import router from '../router.js';
import {getUserInformation} from "../services/CommanServices";

const loginEvent = "loginEvent";
class AuthService extends EventEmitter {
    access_token = null;
    token_type = null;
    refresh_token = null;
    expires_in = null;
    scope = null;
    isAlreadyFetchingAccessToken = false;
    subscribers = [];
    firstRequest = null;

    /* Code to logout a user from all tabs in a browser */
    cookieRegistry = [];
    checkIntervalForSession = 250;  //milli seconds

    hasTokenAndAuth() {
        return !!getCookie('SSO_SESSION_ID') && !!this.getAccessToken(true);
    }
    hasAuthOnly() {
        return !!getCookie('SSO_SESSION_ID') && !this.getAccessToken(true);
    }
    hasJSession() {
        return !!getCookie('__JSSESSIONID');
    }
    storeFirstRequest(firstRequest) {
        localStorage.setItem("firstRequest", JSON.stringify(firstRequest));
    }
    implicitGetToken(to, callback) {
        let me = this;
        implicitAuthorize().then(() => {
            var user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
            if (user_data) {
                callback();
            } else {
                getUserInformation().then((response) => {
                    localStorage.setItem("user_data", JSON.stringify(response.data));
                    callback();
                })
            }
        }).catch(() => {
            me.storeFirstRequest(to);
            callback({
                path: '/login'
            });
        });
    }
    logOut() {
        window.console.log("window.location.origin: " + window.location.origin);
        window.console.log("window.location.href: " + window.location.href);
        const isSsoUser = getCookie("ssoUser");
        const isHaccUser = this.getUserOem() === 'HYUNDAI CA';
        this.clearListenCookieChange();
        delete_cookie('Token', this.getSecondLevelDomain());
        delete_cookie('refreshToken', this.getSecondLevelDomain());
        // will be invalidated in com.smartleads.sso.config.SsoLogoutSuccessHandler.securityContextRepository
        // delete_cookie('SSO_SESSION_ID', this.getSecondLevelDomain());
        delete_cookie('impersonate', this.getSecondLevelDomain());
        window.localStorage.removeItem("user_details");
        window.localStorage.removeItem("user_data");
        window.localStorage.removeItem('oemSkinName');
        var ssoRedirectUrl = getCookie("ssoRedirectUrl");
        if (ssoRedirectUrl != null && ssoRedirectUrl.length > 0) {
            delete_cookie('ssoUser', this.getSecondLevelDomain());
            delete_cookie('__JSSESSIONID', this.getSecondLevelDomain());
            delete_cookie('SSO_SESSION_ID', this.getSecondLevelDomain());
            delete_cookie('ssoRedirectUrl');
            if (isHaccUser === true) {
                window.location.href = ssoRedirectUrl;
            }else{
                window.location = ssoRedirectUrl;
            }
            return true;
        }

        this.emit(loginEvent, {
            loggedIn: false
        });

        window.location.href = process.env.VUE_APP_BASE_URL + "/logout";

        return true;
    }
    getSecondLevelDomain() {
        return location.hostname.substr(location.hostname.indexOf('.') + 1, location.hostname.length);
    }
    getAccessToken(skipError) {
        if (!skipError && !getCookie('Token')) {
            this.generateError();
            return null;
        }
        return this.access_token ? this.access_token : getCookie('Token');
    }
    reGenerateToken(callback) {
        const me = this;
        var userDetails = localStorage.getItem('user_details');

        const refreshToken = (userDetails && JSON.parse(userDetails).refresh_token) || getCookie('refreshToken');

        reGenerateTokenAPI(refreshToken)
            .then(response => {
                if (response.status === 200) {
                    let respData = response.data;
                    me.handleAuthentication(respData);
                    callback(respData.access_token);
                } else {
                    me.generateError();
                }
            })
            .catch((error) => {
                window.console.log('Error regenerate token, logging out', error);
                me.generateError();
                store.dispatch('dwc/sessionExpired', true);
                me.logOut();
            });
    }
    isDealer() {
        return this.isRole('Dealer');
    }
    isAdmin() {
        return this.isRole('admin');
    }
    isImpersonating() {
        return !!getCookie('impersonate');
    }
    getImpersonation() {
        return getCookie('impersonate');
    }
    getUserOem() {
        const user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
        return user_data && user_data.oemName;
    }
    hasBundles() {
        const user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
        return user_data && user_data.hasBundles;
    }
    getUserOemReadable() {
        const oemName = this.getUserOem();
        return oemName === "HYUNDAI" ? "Hyundai" : oemName;
    }
    isRole(role) {
        const user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
        return user_data && user_data.role === role;
    }
    generateError() {
        this.emit(loginEvent, {
            loggedIn: false,
            error: 'Session Expired',
            errorMsg: 'Session expired! please login again.'
        });
    }
    handleAuthentication(authResult) {

        let d = new Date();
        d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString(); //  + authResult.expires_in; 
        document.cookie = "Token=" + authResult.access_token + ";" + expires + ";path=/;domain=" + this.getSecondLevelDomain();
        if (!getCookie('SSO_SESSION_ID')) {
            document.cookie = "SSO_SESSION_ID=" + authResult.access_token + ";" + expires + ";path=/;domain=" + this.getSecondLevelDomain();
        }
        localStorage.setItem('user_details', JSON.stringify(authResult));
        this.localLogin(authResult);
    }
    localLogin(authResult) {
        this.access_token = authResult.access_token;
        this.token_type = authResult.token_type;
        this.refresh_token = authResult.refresh_token;
        this.expires_in = authResult.expires_in;
        this.scope = authResult.scope;

        this.emit(loginEvent, {
            loggedIn: true,
            userDetails: authResult
        });
    }
    onAccessTokenFetched(access_token) {
        this.subscribers = this.subscribers.filter(callback => callback(access_token))
    }
    addSubscriber(callback) {
        this.subscribers.push(callback)
    }
    startListenCookieChange() {
        let me = this;
        me.listenCookieChange('__JSSESSIONID', function() {
            me.logOut();
            me.clearListenCookieChange();
        })
    }
    addInterceptors(axios) {
        var authThis = this;
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401) {
                if (originalRequest.headers['X-Token-Retry']) { // already retried and failed
                    authThis.generateError();
                    store.dispatch('dwc/setLoading', false);
                    store.dispatch('dwc/sessionExpired', true);
                    return new Promise(() => {});
                } else {
                    if (!authThis.isAlreadyFetchingAccessToken) {
                        authThis.isAlreadyFetchingAccessToken = true;

                        // instead of this store call you would put your code to get new token
                        authThis.clearListenCookieChange();
                        authThis.reGenerateToken(function(access_token) {
                            authThis.isAlreadyFetchingAccessToken = false;
                            authThis.startListenCookieChange();
                            authThis.onAccessTokenFetched(access_token);
                        });
                    }
                    return new Promise((resolve) => {
                        authThis.addSubscriber(access_token => {
                            originalRequest.url = originalRequest.url.replace(originalRequest.baseURL, '');
                            originalRequest.headers.Authorization = 'Bearer ' + access_token;
                            originalRequest.headers['X-Token-Retry'] = true;
                            resolve(axios(originalRequest))
                        });
                    });
                }

            } else if (error.response && error.response.status === 403) {
                if (router.currentRoute.path !== '/') {
                    router.push('/');
                }
            }
            return Promise.reject(error)
        })
    }
    clearListenCookieChange() {
        var me = this;
        if (me.cookieInterval) {
            clearInterval(me.cookieInterval);
            me.cookieInterval = null;
        }
        me.cookieRegistry = [];
    }
    listenCookieChange(cookieName, callback) {
        var me = this;
        if (me.cookieInterval) {
            clearInterval(me.cookieInterval);
        }
        me.cookieRegistry = [];
        me.cookieInterval = setInterval(function() {
            if (me.cookieRegistry[cookieName]) {
                if (getCookie(cookieName) !== me.cookieRegistry[cookieName]) {
                    // update registry so we dont get triggered again
                    me.cookieRegistry[cookieName] = getCookie(cookieName);
                    return callback();
                }
            } else {
                me.cookieRegistry[cookieName] = getCookie(cookieName);
            }
        }, me.checkIntervalForSession);
    }
}

const service = new AuthService();
service.setMaxListeners(10);

export default service;