import axios from 'axios'
// import qs from 'qs';
import {store} from '../store'
import auth from "../auth/authService";
// before a request is made start the nprogress
axios.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    return config;
});
// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

export const initDealerInterceptors = () => {
    auth.addInterceptors(axios);
};

export const getDealerServiceData = (data) =>
    new Promise((resolve, reject) => {
        axios.get(`/api/portal/dwc/websiteprogram/async/dealerServiceData?oem=${data.oemName}`)
            .then(response => resolve(response))
            .catch(error => {
                    store.dispatch('dwc/setLoading', false);
                    reject(error);
            })
    });

export const postLoginInfo = (data) =>
    new Promise((resolve, reject) => {
        const config = {
            'dataType': 'json',
            'withCredentials': true
        };
        axios.post('/mocdata/dwc.json', data, config)
            .then(response => resolve(response))
            .catch((error) => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
