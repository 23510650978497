<template>
    <div :class="identifier" @click="onClick">
        <a href="javascript:void(0);">
        <img :src="findIcon()" class="img-responsive" :title="title">
        </a>
    </div>
</template>
<script>
export default {
  name: 'cmsEditorButton',
  props: {
    identifier: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    customMethod: {
      type: Function,
      required: false
    },
    methodParam: {
      type: String,
      required: false
    },
    methodParam2: {
      type: Array,
      required: false
    }
  },
   methods: {
       onClick() {
           if (this.customMethod) {
                if (this.methodParam && this.methodParam2) {
                    this.customMethod(this.methodParam, this.methodParam2);
                } else if (this.methodParam) {
                    this.customMethod(this.methodParam);
                } else {
                 this.customMethod();
                }
           } else {
            window.console.log("Button clicked");
           }
       },
       findIcon() {
            return require('../assets/images/svg/' + this.identifier + '.svg')
       }
   }
}
</script>
