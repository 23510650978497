<template>
  <div class="alert alert-danger alert-dismissible custom-style" style="display: none;">
     <!-- v-if="show" -->
    {{msg}}
    <a href="javascript:void(0);" @click="onLogoutClick"> Click here </a>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      msg: ""
    };
  },
  methods: {
    onLogoutClick() {
      window.console.log('Calling logOut from onLogoutClick in Error.vue');
      this.$auth.logOut()
    },
    handleLoginEvent(data) {
      if (this.$router.history.current.name !== 'login') {
        if (data.error) {
          this.show = true;
          this.msg = data.error;
        } else {
          this.show = false;
          this.msg = "";
        }
      }
    }
  }
};
</script>

<style>
.custom-style {
  margin: 5px !important;
}
</style>

