<template>
    <div>
        <!-- Settings Collapse Dropdown -->
        <div id="settings" class="collapse smart_infobox_awr">
            <div class="smart_infobox_box">
                <div class="container">
                    <div class="smart_infobox_collapse_box">
                        <div class="row">
                            <div class="col-xs-12">
                                <h2>You have signed up for NJ075</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <h3>NJ075 ELMS information</h3>
                                <p>NJ075 You are set up on
                                    <strong>HigherGear</strong>.
                                    <span>Edit</span> to change</p>
                                <p>You have signed the Dealer Information Sharing Agreement.</p>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <h3>NJ075 DWC information</h3>
                                <p>Your Website Services Provider is
                                    <strong>CDK Global</strong>. If you need, you can switch to another one from those
                                    listed below.</p>
                                <p>You have enrolled for
                                    <span>Hyundai Basic Package.</span>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <h3>NJ075 SMARTLEADS information</h3>
                                <p>You are enrolled for SmartLeads
                                    <strong>New Vehicle leads</strong> program, with a operating radius of
                                    <strong>10 miles</strong>. Your Budget Subscription Price is
                                    <strong>$1000</strong> of which
                                    <strong>$125</strong> is remaining for this month.</p>
                                <p>You are enrolled for SmartLeads
                                    <strong>CPO leads</strong> program, with a operating radius of
                                    <strong>50 miles</strong>. Your Budget Subscription Price is
                                    <strong>$1000</strong> of which
                                    <strong>$125</strong> is remaining for this month.</p>
                                <p>You are not enrolled for SmartLeads Inventory leads program.
                                    <span>Enroll now!</span>
                                </p>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <h3>NJ075 SHOPPER CONTACT</h3>
                                <p>Your operating hours for the Concierge Program are as follows. Click on the links
                                    below to edit any of them:</p>
                                <p style="margin-bottom:0;">Mon:
                                    <span>8:00 AM</span> -
                                    <span>12:00 PM</span>
                                    <span>6:00 PM</span> -
                                    <span>10:00 PM</span>
                                </p>
                                <p style="margin:0;">Tue:
                                    <span>8:00 AM</span> -
                                    <span>10:00 PM</span>
                                </p>
                                <p style="margin:0;">Wed:
                                    <span>8:00 AM</span> -
                                    <span>12:00 PM</span>
                                    <span>8:00 PM</span> -
                                    <span>12:00 AM</span>
                                </p>
                                <p style="margin:0;">Thu:
                                    <span>8.00 AM</span> -
                                    <span>11:59 PM</span>
                                </p>
                                <p style="margin:0;">Fri:
                                    <span>8:00 AM</span> -
                                    <span>11:59 PM</span>
                                </p>
                                <p style="margin:0;">Sat: All Day</p>
                                <p style="margin-top:0;">Sun: All Day</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Settings Collapse Dropdown Ends Here -->
        <!-- Alerts Collapse Dropdown -->
        <div id="alertbox" class="collapse alerts_awr">
            <div class="alerts_box">
                <div class="container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div>
        </div>
        <!-- Alerts Collapse Dropdown Ends Here -->
        <div class="web_tabs">
            <div class="container">
                <ul class="nav nav-tabs">
                    <!-- Customer / Analytics Tabs -->
                    <li class="active">
                        <a data-toggle="tab" href="javascript:void(0);" class="uptabs">{{isGenesis ? $t("app.Smart_Portal") : $t("app.SmartPortal")}}</a>
                    </li>
                    <li v-if="(UserDetails && UserDetails.biDashboards.length === 1)">
                        <a data-toggle="tab" @click="openDashboard(getKey(UserDetails.biDashboards[0]))" href="" class="uptabs_bi">{{isGenesis ? $t("app.Smart_Analytics") : $t("app.SmartAnalytics")}}</a>
                    </li>
                    <li v-else-if="(UserDetails && UserDetails.biDashboards.length > 1)" class="dropdown">
                        <a href="javascript:void(0);" class="uptabs_bi dropdown-toggle" data-toggle="dropdown">
                            <span>{{isGenesis ? 'Smart Analytics' : 'SmartAnalytics'}}</span>
                            <span>
                                <img src="../../assets/images/svg/chevron_down.svg" class="chevron_down">
                            </span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="dropdown dropdown-submenu" v-for="dashboard in UserDetails.biDashboards" :key="getKey(dashboard)">
                                  <a href="javascript:void(0);" @click="openDashboard(getKey(dashboard))" class="selected_biDashboard">
                                    {{dashboard[getKey(dashboard)]}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li v-if="isAdmin">
                        <a data-toggle="tab" @click="openAdminPortal" :href="UserDetails.lmsExternalUrl" class="uptabs">{{isGenesis ? $t("app.Admin_Portal") : $t("app.AdminPortal")}}</a>
                    </li>
                    <span class="top_right_icons">
                        <li>
                            <span>
                                  <select v-if="locales.length > 0" v-model="$i18n.locale" @change.prevent="changeLocale" class="locale-switcher">
                                    <option :value="locale.code" v-for="locale in locales" :key="locale.code">{{locale.name}}</option>
                                  </select>
                                  <a @click="onLogoutClick" href="javascript:void(0);" style="display: inline;">
                                      <img src="../../assets/images/svg/signout.svg" class="signout_icon" alt="Logout" title="Logout">
                                  </a>
                            </span>
                        </li>
                    </span>
                </ul>
            </div>
        </div>
        <!-- Top Tabs Ends Here -->
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { getSupportedLocales, mapLocale, userLocale } from "@/util/i18n/supported-locales"
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
  //   name: 'topHeader',
  computed: {
    ...mapState('dwc', ['UserDetails']),
    locales() {
      let supported = getSupportedLocales();
      if (this.UserDetails) {
        supported = supported.filter(s => this.UserDetails.availableLanguagesCodes.includes(s.code));
      }
      return supported;
    },
    isAdmin() {
      if (this.UserDetails) {
        return this.UserDetails.role === 'admin';
      }
      return false;
    },
    isGenesis() {
      return this.UserDetails && this.UserDetails.oemSkinName === 'genesis';
    }
  },
   watch: {
     UserDetails: {
       immediate: true,
        handler() {
         if (this.UserDetails) {
            if (this.$i18n.locale != userLocale()) {
                this.changeLocaleByValue(userLocale());
                this.$i18n.locale = userLocale();
            }
         }
        }
      },
   },
  methods: {
    onLogoutClick() {
      window.console.log('Calling logOut from onLogoutClick in topheader.vue');
      this.$auth.logOut()
    },
    openDashboard(page) {
      window.location = page ? page : '';
    },
    getKey(item) {
      // return first key of the object
      for (let key in item) {
        return key;
      }
      return '';
    },
    openAdminPortal() {
      window.location = this.UserDetails.lmsExternalUrl;
    },
    changeLocaleByValue(locale){
          this.$store.dispatch('dwc/changeUserLanguage', locale).then();
          const user_data = localStorage.getItem('user_data')
          ? JSON.parse(localStorage.getItem('user_data'))
          : '';
          user_data.language = mapLocale(locale);
          localStorage.setItem("user_data", JSON.stringify(user_data));
          if (user_data.language === 'ENGLISH') {
            this.$store.dispatch('dwc/loadProductData', user_data.oemName);
          } else {
            this.$store.dispatch('dwc/loadProductData', user_data.oemName + "_" + user_data.language);
          }
          this.$router.push(`/${locale}`).catch(error => {
            if (!isNavigationFailure(error, NavigationFailureType.redirected)) {
              Promise.reject(error)
            }
            window.console.error(error);
          })
    },
    changeLocale(e) {
      this.changeLocaleByValue(e.target.value);
    }
  }
}

</script>
<style>
    @media (max-width: 766px) {
        .web_tabs {
            height: 38px;
        }
    }
    .locale-switcher {
      color: #000000;
      display: inline;
    }
</style>