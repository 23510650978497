<template>
    <div
            :class="[SessionExpired ? 'open'  : 'close']"
            class="modal fade change_password opacity-1"
            role="dialog"
    >
        <div class="covering_div"></div>
        <div class="modal-dialog no_transform" style="width: 35%;">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <div class="info_heading">
                        <h3>Session Expired</h3>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="changepwd_form">
                        <div class="form-group">
                            <p>Your session has expired.</p>
                        </div>
                        <button class="contact_submit" @click="hideModel" type="button">Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    export default {
        name: "showError",
        computed: {
            ...mapState("dwc", ["SessionExpired"])
        },
        methods: {
            hideModel() {
                if (this.$auth.isImpersonating()) {
                    this.$store.dispatch('dwc/deImpersonateUserByLogin').then(() => {
                        window.location.href = "/";
                    });
                    return;
                }

                window.console.log('Calling logOut from hideModel in ShowSessionExpiredError');
                if (this.$auth.logOut()) {
                    return;
                }
                this.$router.push({path: "login"});
                this.$emit("hideModel");
                this.$store.dispatch('dwc/sessionExpired', false);
                window.location.reload(true);
            }
        }
    };
</script>
<style>
    .open {
        display: block;
    }

    .close {
        display: none;
    }
</style>
