import authService from "../auth/authService";
import DateUtil from "../util/DateUtil";

export default {
    install(Vue) {
        Vue.prototype.$auth = authService;

        Vue.mixin({
            created() {
                if (this.handleLoginEvent) {
                    authService.addListener("loginEvent", this.handleLoginEvent);
                }
            },
            destroyed() {
                if (this.handleLoginEvent) {
                    authService.removeListener("loginEvent", this.handleLoginEvent);
                }
            },
            methods: {
                convertDate(date) {
                    const selectedDate = date ? date : null;
                    let finalDate = null;
                    if (selectedDate) {
                        const dt = new Date(selectedDate);
                        finalDate = DateUtil.formatMMDDYYYY(dt);
                    }
                    return finalDate;
                }
            }
        });
    }
};
